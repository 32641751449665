.authors {
margin: 100px 0;
column-count: 4;

@include media($tablet) {
  column-count: 2;
}

@include media($mobile-narrow) {
  columns: initial;
}

  &__post {
    break-inside: avoid;
    vertical-align: top;
    margin-bottom: 30px;
    text-transform: uppercase;
    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      display: block;
    }
    h2 { 
      font-size: 22px; 
      font-weight: $bold; 
      margin: 0;margin-bottom: 15px;
    }
    p { 
      font-size: 18px; 
      font-weight: $semibold;
      margin: 0; 
    }
  }
}