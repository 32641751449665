.archive {

  &__heading {
    margin: 70px 20px 50px 20px;

    &--first {
      h2  {
        font-size: 32px;
        font-weight: $ultra;
        margin: 0;
        text-transform: uppercase;
      }

      h3 {
        font-size: 24px;
        font-weight: $semibold;
        margin: 0;
      }
    }

    @include media($tablet) {
      margin: 30px 20px 20px; 
      
      h2 {
        font-size: 18px;
      }

      h3 { 
      font-size: 16px; 
      }
    }
  }

  &__works {
    margin: 70px 0;
    display: flex;
    align-items: top;
    flex-wrap: wrap;

    @include media($tablet) {
      margin: 30px 0;
    }
  }

		&__work {
			width: percent(1,3);
			text-align: center;
			margin: 20px 0;
			padding: 0 20px;

			img {
				display: inline;
				vertical-align: bottom;
        max-height: 200px;
			}

      a {
        display: block;
        color: $black;
        &:hover {
          text-decoration: underline;
        }
      }

      @include media($tablet) {
        width: percent(1,2);
      }

      @include media($mobile-narrow) {
        width: auto;
      }
		}

    &__workName, &__workAuthor {
      margin-top: 15px;
      font-size: 16px;
      font-weight: $bold;
      margin-bottom: 0;
    }
    
    &__workAuthor {
      margin-bottom: -15px;
    }

    &__workExhibition {
      font-size: 14px;
      font-weight: $bold;
      text-transform: uppercase;
      margin: 0;
    }

    &__workAward {
      font-size: 14px;
      text-transform: uppercase;
      margin: 0;
    }
}