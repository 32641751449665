.footer { 
  padding-bottom: 25px;
  font-size: 15px;
  &__Logos { 
    @include clearfix; 
    margin-top: 120px; 

    @include media($tablet) {
      margin-top: 50px;
    }
  }
    &__Left { 
      float: left; 
      margin: 0;  
    }
    &__Right { 
      float: right; 
    }
    &__msu {
      width: 200px; 
      height: 90px;

      @include media($mobile) {
        width: 110px;
      }
    }
    &__tcom {
      width: 120px; 
      height: 90px; 
      fill: $brand;

      @include media($mobile) {
        width: 60px;
      }
    }

  &__Copyright { 
    display: block; 
    padding-top: 40px; 
    border-top: 1px 
    solid $black;
    a {
      color: $black;
      &:hover {
        text-decoration: underline;
      }
    }
    p { 
      font-size: $medium; 
      margin: 0; 
      display: inline-block;
      margin-right: 2%; 
    }
  }
}
