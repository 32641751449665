// Dev specific
#__bs_notify__ { position: fixed; top: 50%; left: 50%; margin-left: -125px !important; width: 250px; height: 50px; border-radius: 0 !important; opacity: 0.5; }

// Template styles
a {text-decoration: none}
img { display: block; }
body { 
  background: white; 
  font-family: 'Open Sans', sans-serif;
  padding: 0; 
  margin: 0;

  @include media($tablet-wide) {
    padding-top: 50px;
  }
}
