.about {
  margin-top: 75px;
  display: flex;
  &__textBox {
    width: 60%;
    font-family: $body-font;
    margin-right: 40px;
    h2  {
      font-size: 30px;
      font-weight: $bold;
      text-transform: uppercase;
    }
    p {
      font-size: $xlarge;
      margin-top: 40px;
    }

    @include media($tablet) {
      width: auto;
      margin: 0;
    }
  }
  
  &__description {
    width: 40%;
    padding: 50px;
    margin-top: -60px;

    p {
      font-size: 26px;
      font-weight: $regular;
    }
  }

  @include media($tablet) {
    width: auto;
    margin-top: 20px;
  }
}