div.gform_wrapper {
  .validation_error { margin: 10px; width: 100% !important; }
  .gform_footer { margin: 10px; }
  li.gfield {
    ul { margin: 0; padding: 0; }
    svg { width: 20px; height: 20px; fill: $brand; position: absolute; left: -35px; top: 0; }

    fieldset { background: $gallery; border: 1px solid darken($gallery, 5%); border-radius: 10px; padding: 100px 60px 60px 60px; margin: 30px 10px; width: 100%;
      legend { display: block; margin-left: -60px; margin-bottom: 20px; font-weight: $bold; padding-left: 60px; padding-bottom: 8px; border-bottom: 1px solid $brand; display: inline-block; font-size: em(20px); color: $gray; position: absolute; top: 30px;
        // &--White { @extend .fieldset-title; color: $white; }
      }
      // &--without-Title { @extend .fieldset; padding-top: 20px; }
      // &--Dark { @extend .fieldset; background: $dgray; }

      .warning { position: relative; left: 0; margin-left: 20px; margin-top: 40px; margin-bottom: 50px; font-size: em(18px, $large);
        p { margin-top: 0; }
        strong { color: $brand; }
        svg { width: 20px; height: 20px; fill: $brand; position: absolute; left: -35px; top: 0; }
      }


      .gfield { display: block; @include clearfix; max-width: 700px; font-size: em(14px, 18px); padding: 0; margin: 0; position: relative;
        .gfield_label { line-height: 1.2; display: inline-block; vertical-align: middle; }

        .gfield_label, .ginput_container { width: auto; float: right; margin: 5px 0; line-height: 44px; }
        .gfield_label { float: left; clear: right; width: percent(200px, 700px); font-weight: $bold; }
        .ginput_container { width: percent(500px, 700px); position: relative;
          // span { position: absolute; right: 100%; margin-right: 20px; color: $gray; }
        }

        // Remove asterisk
        .gfield_required { display: none !important; }

        .gfield_description { position: absolute; left: 100%; padding-left: 15px !important; margin-left: 40px !important; margin-right: 20px; color: $gray; width: 100% !important; max-width: 200px;
          &:before { content: ""; display: block; width: 20px; height: 20px; background: url(../images/exclamation.png) no-repeat center; position: absolute; right: 100%; }

          &.validation_message { position: relative; left: auto; }
        }

        .ginput_container_date { float: left;
          .ginput_container { width: percent(160px, 500px); margin-right: percent(10px, 500px); float: left;
            &:last-of-type { margin-right: 0; }
          }
        }

        .gform_drop_instructions { margin-right: 20px; font-size: 16px; }

        #{$all-text-inputs}, select, textarea { border: 1px solid darken($gallery, 5%); padding: 10px; border-radius: 5px; width: 100%; vertical-align: middle; background: $white; box-sizing: border-box !important; line-height: 1.5; }
        input[type='date'] { width: 200px; }
        input[type='file'] { clear: both; color: $white;
          &::-webkit-file-upload-button { background: $brand; display: block; border: 0; color: $white; padding: 10px; border-radius: 5px; margin-right: 30px; }
        }
        textarea { height: 100px; }
      }

      .gfield_html { max-width: none; }
      .gfield_checkbox {
        li { height: 44px; line-height: 44px; }
        input { display: none;
          & + label { display: inline-block; vertical-align: middle; cursor: pointer; transition: 200ms; color: $brand; line-height: 1; margin-left: 15px;
            &:before { content: ""; display: inline-block; width: 20px; height: 20px; border: 1px solid $brand; vertical-align: middle; margin-right: 10px; transition: 200ms; margin-top: -3px; }
          }

          &:checked {
            & + label { font-weight: $regular;
              &:before { background: $brand; }
            }
          }
        }
      }

      img { display: inline-block; margin-right: 10px; }
    }

    &.english, &.croatian {
      .gfield_label { position: relative;
        &:after { font-size: 14px; content: "(hr)"; color: $gray; position: absolute; right: 20px; }
      }
    }

    &.english {
      .gfield_label { font-size: 0;
        &:after { content: "(en)"; }
      }
    }
  }

  // Custom styles for upload section
  #field_1_22, #field_1_33, #field_1_40, .wide { max-width: none;
    .gfield_label { display: none; }
    .ginput_container { width: auto; float: none; max-width: none; }
  }

  #field_1_33 { font-size: 16px; }

  #field_1_25 {
    fieldset { background: $dgray; color: $white;
      legend { color: inherit; }
    }
  }

  #field_1_27 {
    fieldset {
      // legend { display: none; }
      textarea { height: 200px; }
    }
  }

  .button { border-radius: 5px; padding: 9px 22px 11px 22px; font-size: 16px; color: $white; border: 0; display: inline-block; background: $brand; margin: 20px 0;
    &:hover { background: $gray; color: $white; }
  }

  .gform_footer {
    .button { padding: 20px 40px; font-size: 24px !important; }
  }
}

.gform_confirmation_message { padding: 40px 20px; font-size: em(24px); color: $brand; }
