// Alpha color
@function alpha-color($color: $black, $opacity: 0.5) { 
  $alpha-color-value: rgba($color, $opacity); 
  @return $alpha-color-value;
}

// Align to center
@mixin center($axis: "both"){
  position:absolute;
  @if $axis == "y"{
    top:50%;
    transform: translateY(-50%);
  }
  @if $axis == "x"{
    left:50%;
    transform: translateX(-50%);
  }
  @if $axis == "both"{
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
  }
}

// Target/context formula
@function percent($target, $context: $max-width) {
  $target-context-num: $target/$context * 100%;
  @return $target-context-num;
}

// EM formula
@function em($target, $context: $font-size) {
  $target-context-num: $target/$context * 1em;
  @return $target-context-num;
}

// Scollbars
@mixin scrollbars($size: 8px, $foreground-color: darken($white, 20%), $background-color: darken($white, 10%)) {
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: 10px;
  }
}

// Elements
@mixin inlineNav($ul: true) {
  @if $ul == true {
    ul { margin: 0; padding: 0; }
  }
  li { display: inline-block; vertical-align: top; }
}

// More button
  @mixin moreButton($fgcolor: $white, $bgcolor: $white, $fghover: $bgcolor, $bghover: $brand) { display: inline-block; padding: 12px 60px; background: transparent; color: $fgcolor; text-decoration: none; transition: 500ms 300ms; border: 0; text-transform: uppercase; font-weight: $regular; letter-spacing: 3px; font-family: $title-font; font-size: 16px; border: 1px solid $fgcolor; border-top: 0; border-bottom: 0; position: relative; overflow: hidden;
    // &:before { height: 100%; width: 100%; position: absolute; content: ""; display: block; background: alpha-color($bgcolor, 0.3); top: 0; left: -110%; transition: 400ms $ease-in-out-cubic; transform: skew(45deg); }
    &:before, &:after { content: ""; display: block; width: 100%; top: 0; left: 0; height: 1px; background: $fgcolor; position: absolute; transition: 300ms $ease-in-out-expo; }
    &:after { top: auto; bottom: 0; left: auto; right: 0; }

    // &:hover { background: $bghover; color: $fghover; border-color: $bghover; }
    &:hover { color: $fghover; 
      // &:before { transform: translateX(220%) skew(45deg); opacity: 0; }
      &:before, &:after { width: 50%; }
    }
  }
  
// Overlay
  @mixin overlay($opacity: 0.5, $color: $black, $front: false, $element: before) { position: relative; z-index: 1;
    &:#{$element} { content: ""; display: block; position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: alpha-color($color, $opacity); @if $front == true { z-index: 1; } @else { z-index: -1; } }
  }

// Loading spinner
 @mixin loader($size: 30px, $color: $accent, $center: false, $duration: 1s) { font-size: 0; width: $size; height: $size; border: 3px solid $color; border-left: 3px solid transparent; border-radius: 50%; animation: rotate $duration linear infinite; 

    @if $center == true { position: absolute; top: 50%; left: 50%; margin-left: -$size/2; margin-top: -$size/2; }
  }