// Highlight home if on single news item
.single-post .menu-item-home {
  & > a {
    color: $white;
  }
}

// Highlight archive if on exhibition
.archive-work .menu-archive, .archive .menu-archive, .single-work .menu-archive {
  & > a {
    color: $white;
  }
}

.page-template-page-authors .menu-archive.menu-item-has-children {
  & > a {
    color: $white;
  }
}

#menuToggle {
  display: none;

  &:checked ~ .navigation__list {
    transform: translateX(0);
  }
}
.menuToggle {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px 15px;
  z-index: 2020;

  svg {
    fill: $white;
    width: 30px;
    height: 30px;
    display: block;
  }

  @include media($tablet-wide) {
    display: block;
  }
}

.navigation {
  background-color: $dgray;
  color: $lgray;

  @include media($tablet-wide) {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2000;
    line-height: 42px;
  }

& .center {
  display: flex;

  @include media($tablet-wide) {
    display: block;
  }
}

  &__svg {
    flex: 1;

    @include media($tablet-wide) {
      display: inline-block;
    }
  }

  &__logo {
    margin-top: 5px;
    height: 45px;
    width: 100%;
    max-width: 100px;
    fill: white;

    @include media($tablet-wide) {
      height: 30px;
      margin-top: 10px;
      margin-bottom: -5px;
    }
  }

  &__list {
    flex: 3;
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    padding: 0;
    margin: 0;

    @include media($tablet-wide) {
      transform: translateX(100%);
      transition: 300ms $ease-in-out-quint;
      display: block;
      font-size: 15px;
      position: fixed;
      width: 300px;
      top: 50px;
      bottom: 0;
      right: 0;
      z-index: 2000;
      background: $black;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }

    li {
      display: inline-block;
      text-transform: uppercase;
      font-weight: $semibold;
      position: relative;

      @include media($tablet-wide) {
        display: block;
      }

      a {
        display: block;
        line-height: 56px;
        padding: 0 10px;
        color: $gray;

        &:hover {
          color: white;
        }

        @include media($tablet-wide) {
          line-height: 40px;
        }
      }

      &.current-menu-item {
        a {
          color: $white;
        }
      }

      &.menu-item-has-children {
        a {
          &:after {
            content: "";
            display: inline-block;
            @include triangle(12px, $gray, down);
            vertical-align: 3px;
            margin-left: 5px;
          }
        }
      }

      &:hover {
        ul {
          opacity: 1;
        }
      }

      ul {
        &:before {
          content: "";
          display: block;
          @include triangle(12px, $dgray, down);
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -6px;
        }
        transition: 300ms;
        opacity: 0;
        padding: 15px 0 10px 0;
        position: absolute;
        top: 56px;
        left: 0;
        background: #B8B8B8;

        li {
          display: block;
        }

        a {
          &:after {
            display: none !important;
          }
          line-height: 20px;
          font-size: 15px;
          display: block;
          color: #636363;
          padding: 5px 20px;

          &:hover {
            color: $white;
          }
        }

        @include media($tablet-wide) {
          position: static;
          opacity: 1;
          visibility: visible;
          transform: none;
          padding: 0;
          background: transparent;
          background: lighten($black, 8%);
          padding-left: 10px;

          &:before {
            display: none;
          }

          li { 
            display: block; 
          }

          a {
            color: $gray;
            line-height: 40px;
            padding: 0 10px;
          }
        }
      }
    }
  }

  &__find {
    // find ikonica
  }
}

// archives navigation

.exhibitionsNav {

  &__list {
    padding: 0;
  }

  &__listItem {
    display: inline-block;
    margin-right: 20px;
    margin-left: 0;

    @include media($mobile) {
      display: block;
    }

    a {
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    font-weight: $semibold;

      &:hover {
          color: $gray;
      }

      @include media($mobile) {
        padding: 10px 20px;
        text-align: center;
        display: block;
      }
    }
  }
}
