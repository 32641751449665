.slider { 
  margin: 25px -5px 90px -5px;
  display: table;
  width: 100%;
  height: 665px;

  @include media($tablet) {
    margin: 0 0 20px;
  }
  
  &--large {
    height: 935px;
  }

  &:hover {
    .slider__image {
      width: 15%;
      height: 135px;
    }

    .slider__overlay {
      opacity: 0 !important;
    }
  }

  &__row {
    display: table-row;
    width: 100%;
  }

  &__image {
    width: 15%;
    height: 135px;
    display: table-cell;
    background-size: cover;
    background-position: center;
    position: relative;
    border: 5px solid $white;
    background-color: $black;
    overflow: hidden;
    transition: 1s;

    @include media($tablet) {
      display: block;
      width: auto !important;
    }

    &.is-active, &:hover {
      width: 55%;
      height: 665px;

      .slider__overlay {
        opacity: 1;
      }

      @include media($tablet) {
        height: 400px;
      }
    }

    &:hover {
      width: 55% !important;
      height: 665px !important;

      .slider__overlay {
        opacity: 1 !important;
      }

      @include media($tablet) {
        display: block;
        width: auto !important;
        height: 400px !important;
      }
    }
  }

  &__overlay {
    background: alpha-color($black, 0.9);
    color: $white;
    padding: 60px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    transition: 600ms 600ms;
    min-width: 630px;

    @include media($tablet) {
      min-width: 0;
      width: 100%;
      padding: 5%;
    }

    &--award {
      padding: 20px 40px 30px 40px;
    }
  }

  &__overlayAward { 
    float: left;
    margin: 0;
    margin-right: 60px;
    text-transform: uppercase;
    font-size: 26px;
  }

  &__overlayMeta {
    float: left;

    h2, p {
      margin: 0; 
      font-size: 22px;
      line-height: 1.4;
    }

    h2 {
      font-weight: $regular;
    }

    p {
      font-weight: $bold;
    }
  }

  &__overlayTitle { 
    margin: 0; 

    @include media($tablet) {
      font-size: 18px;
    }
  }
}