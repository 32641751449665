$icons: (
  sprite: (width: 3499px, height: 1353px, pngPath: '../images/sprite.png', svgPath: '../images/sprite.svg'),
  arrow: (width: 41.3px, height: 68.4px, backgroundX: 0px, backgroundY: 0px),
    arttlogo: (width: 593.7px, height: 191.9px, backgroundX: -42px, backgroundY: -69px),
    close_icon: (width: 86.9px, height: 86.9px, backgroundX: -636px, backgroundY: -261px),
    header: (width: 1940.5px, height: 188.1px, backgroundX: -723px, backgroundY: -348px),
    icon_strelica: (width: 92.1px, height: 175.7px, backgroundX: -2664px, backgroundY: -537px),
    iconinfo: (width: 108.5px, height: 126.8px, backgroundX: -2757px, backgroundY: -713px),
    menu: (width: 384.97px, height: 384.97px, backgroundX: -2866px, backgroundY: -840px),
    msu: (width: 159.9px, height: 62.6px, backgroundX: -3251px, backgroundY: -1225px),
    tcom: (width: 88px, height: 64.3px, backgroundX: -3411px, backgroundY: -1288px),
  );

$sprite: map-get($icons, sprite) !default;
$baseFontSize: 16px !default;

// Gets an attribute from the sass map
@function sprite-attr($icon, $attr) {
  $newIcon: map-get($icons, $icon);
  @if $newIcon == null {
    @warn "Can't find an icon with the name #{$icon}";
  }
  @return map-get($newIcon, $attr);
}

@function icon-attr($icon) {
  $attr: (
    width: sprite-attr($icon, width),
    height: sprite-attr($icon, height),
    x: sprite-attr($icon, backgroundX),
    y: sprite-attr($icon, backgroundY)
    );
  @return $attr;
}

// Sets background image and size
%sprite { display: inline-block; vertical-align: middle; font-size: 0; font-style: normal; }

@mixin sprite($icon, $type: all) {
  $iconMap: icon-attr($icon);

  @extend %sprite;
  width: map-get($iconMap, width);
  height: map-get($iconMap, height);

  // Outputs background position in em
  @if $type != symbol {
    background-position: map-get($iconMap, x) map-get($iconMap, y);
    background-image: url(map-get($sprite, svgPath)); 
    background-size: map-get($sprite, width) map-get($sprite, height); 
  }
} 

svg, symbol { width: 0; height: 0;
  circle, ellipse, g, line, path, polygon, polyline, rect { fill: inherit !important; stroke: inherit !important; }
}

// .icon-arrow { @include sprite(arrow); }
.symbol-arrow { @include sprite(arrow, symbol); }
// .icon-arttlogo { @include sprite(arttlogo); }
.symbol-arttlogo { @include sprite(arttlogo, symbol); }
// .icon-close_icon { @include sprite(close_icon); }
.symbol-close_icon { @include sprite(close_icon, symbol); }
// .icon-header { @include sprite(header); }
.symbol-header { @include sprite(header, symbol); }
// .icon-icon_strelica { @include sprite(icon_strelica); }
.symbol-icon_strelica { @include sprite(icon_strelica, symbol); }
// .icon-iconinfo { @include sprite(iconinfo); }
.symbol-iconinfo { @include sprite(iconinfo, symbol); }
// .icon-menu { @include sprite(menu); }
.symbol-menu { @include sprite(menu, symbol); }
// .icon-msu { @include sprite(msu); }
.symbol-msu { @include sprite(msu, symbol); }
// .icon-tcom { @include sprite(tcom); }
.symbol-tcom { @include sprite(tcom, symbol); }
