.site {
  padding-top: 0;
}

.header {
  margin-top: 40px;

  @include media($tablet) {
    margin-top: 10px;
  }

  &__svg {
    height: 100%;
    width: 100%;
    
    @include media($tablet) {
      height: 100px;
    }
  }
}