.galleryBox {
    margin: 25px 0;
}

.gallery {
margin: 10px 0;
height: 665px;
width: 100%;
display: flex;


&__image {
  width: 15%;
  height: 100%;
  border-left: 10px solid white;
  background-color:lightseagreen;

    &--first {
      width: 60%;
      height: 100%;
      background-color:coral;
      border: none;
    }
  }
}

.gallerySmall { 
height: 138px;
width: 100%;
display: flex;

&__image {
  width: 15%;
  height: 100%;
  border-left: 10px solid white;
  background-color:lightseagreen;

    &--first {
      width: 60%;
      height: 100%;
      background-color:coral;
      border: none;
    }
  }
}