.articles {
  width: 100%;
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
  @include media($tablet) {
    display: block;
  }
}

  .article {
    cursor: pointer;
    margin-top: 40px;
    width: 48.25%;
    background-color: $contentbox;
    position: relative;

    &:nth-child(odd) {
      margin-right: 3.5%;
    }

    @include media($tablet) {
      width: auto;
      margin: 0 0 20px !important;
    }
  
    &__link {
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0; 
        right: 0;
        bottom: 0;
        left: 0;
      }
      display: flex;
      transition: 300ms;
      color: inherit;

      &:hover {
        opacity: 0.8;
      }
    }

    &__img {
      width: percent(280px, 540px);
      overflow: hidden;
      position: relative;
      max-height: 180px;
      display: flex;

      img {
        height: auto;
        width: 100%;
        object-fit: cover;
      }

      @include media($mobile) {
        display: none;
      }
    }

    &__text {
      width: percent(260px, 540px);
      height: 180px;
      overflow: hidden;
      padding: 8px 15px 0px 15px;

      @include media($mobile) {
        width: auto;
      }
    }
      
      &__title {
        font-size: $xlarge;
        font-weight: $semibold;
        margin: 10px 0;
      }

      &__date {
        font-size: $small;
        margin: 10px 0;
      }

      &__excerpt {
        font-size: $medium;
        p {
          margin: 10px 0;
        }
      }
  }