// COLORS
  $brand: #e5007e;

  $white: #FFF;
  $lgray: #edefec; // side map 
  $gray: #969696; // nav color
  $dgray: #232323; // nav bgc
  $black: #000;
  $contentbox: #F3F3F3;
  $gallery: #EEEEEE;

  $success: #A7C520;
  $error: #CD2C24;
  $warning: #FFB03B;  

  $facebook: #3B5997;
  $twitter: #4099FF;

// TYPE
  $font-size: 16px;
  $line-height: 1.5;
  $body-font: 'Open Sans', sans-serif;
  $lato-font: 'Lato', sans-serif;

  $thin: 100;
  $light: 300;
  $regular: 400;
  $mediumbold: 500;
  $semibold: 600;
  $bold: 700;
  $ultra: 900;

  $xxsmall: em(10px);
  $xsmall: em(12px);
  $small: em(14px);
  $medium: em(16px);
  $large: em(18px);
  $xlarge: em(20px);
  $xxlarge: em(24px);
  $slider: em(54px);
  $title: em(32px);
  $article: em(48px);
  $articlebody: 19px;

// DEFAULTS
  body { font: #{$font-size}/#{$line-height} $body-font; }
  img { max-width: 100%; height: auto; }

  .center { @include outer-container; padding-left: 10px; padding-right: 10px; }
  .clearfix { @include clearfix; }

  ::selection { background: darken($white, 20%); text-shadow: none; }
