// BOWER
@import "../../assets/bower_components/normalize.css/normalize.css";

// BOURBON/NEAT
@import "bourbon";
@import "neat";

// CORE
@import "core/grid-settings";
@import "core/defaults";
@import "core/mixins";
@import "core/animations";

// PLUGINS
@import "../../assets/sass/plugins/animate.css";
@import "../../assets/sass/plugins/flickity.css";

// MODULES
@import "modules/sprite"; // SVG sprites
@import "modules/template"; // Main template file

@import "modules/home"; // Info box
@import "modules/info"; // Info box
@import "modules/slider"; // Sliders
@import "modules/gallery"; // Gallery
@import "modules/about"; // HT Reward page
@import "modules/archives"; // Archives page
@import "modules/work"; // Single work page
@import "modules/authors"; // Authors page 
@import "modules/exhibitions"; // Exibitions page


  // Partials
  @import "partials/header";
  @import "partials/footer";
  @import "partials/navigation";


  // Pages
  @import "modules/forms"; // Forms helper

// PARTIALS
