  .exhibitions {
  margin-top: 80px;

  &__post {
    padding-bottom: 100px;
  }

  &__link {
    color: inherit;
    display: block;
  }
  &__image {
    margin: 20px 0;
    img {
      max-width: none;
      width: 100%;
    }
  }
  &__desc {
    margin: 0 20px 10px;
    h2 {
      font-size: 14px;
      font-weight: $semibold;
      text-transform: uppercase;
      margin-bottom: 0;
    }
    h3, p {
      font-size:14px;
      font-weight: $regular;
      margin-top: 5px;
      margin-bottom: 100px;
      display: inline;
    }

    a {
      color: $black;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__heading {
    margin: 0px 20px;
    h2 {
      font-size: 30px;
      font-weight: $bold;
      text-transform: uppercase;
      margin: 0;

      @include media($tablet) {
        font-size: 18px;
      }
    }
  }
}
