#infoToggle {
  display: none;

  &:checked ~ {
    .infoBox {
      transform: translate(0);
      transition: 600ms;
    }
  }
}
.infoBox {
  position: fixed;
  width: 440px;
  background-color: $lgray;
  right: 0;
  top: 30%;
  box-sizing: content-box;
  padding: 10px;
  transform: translateX(100%);
  transition: 600ms;

  /*
  &:hover {
    transform: translate(0);
    transition: 600ms;
  }
  */

  &__icon {
    height: 66px;
    width: 66px;
    position: absolute;
    transform: translate(-96px, -10px);
    background-color:$lgray;
    padding: 10px;
    cursor: pointer;

    @include media($tablet) {
      width: 30px;
      height: 30px;

      transform: translate(-60px, -10px);
    }
  }

  &__content {
    &__firstRow {
      display: flex;
      .textBox {
        width: 50%;

        @include media($mobile) {
          width: auto;
        }
      }

      font-family: $lato-font;
      background-color: white;
      padding: 5px;

      h3, p { margin: 5px; }
      h3 {font-size: $xlarge;font-weight: $ultra;}
      #adresa {font-size: $large;}
      #radnoVrijeme {font-size: $small;}

    }
    .arrowBox {
      width: 50%;

      &__icon {
        width: 100%;
        height: 100%;
        fill: $lgray;
      }

      @include media($mobile) {
        display: none;
      }
    }

    &__map {
      margin-top: 10px;
    }
  }

  @include media($mobile) {
    width: 220px;
  }
}
// icon return to exibitions
.arrowBox__icon--return {height: 12px; width: 12px; display: inline-block;transform: rotate(180deg); }
