.work {
  &__jury {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $white;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-flow: column;
    opacity: 0;
    visibility: hidden;
    transition: 300ms;

    &.is-active {
      opacity: 1;
      visibility: visible;
    }
  }

  &__juryContent {
    max-width: 700px;
    margin: 30px auto;
    overflow-y: auto;
    padding: 0 20px;

    h2 {
      font-size: 28px;
      font-weight: $semibold;
    }

    p {
      width: auto;
      float: none;
      font-size: 20px;
    }
  }

  &__juryList {
    margin-top: 50px; 
    h3, p {
      font-weight: $regular;
      display: inline;
      font-size: 13px;
      line-height: 1;
    }
  }

  &__juryClose {
    display: inline-block;
    margin-top: 30px;
    font-weight: $semibold;
    font-size: 14px;
    color: $black;
    transition: 200ms;

    &:hover {
      opacity: 0.5;
    }
   
    svg {
      margin-right: 10px;
      width: 40px;
      height: 40px; 
      vertical-align: middle;
    }
  }

  &__awardYear {
    font-size: 32px;
    font-weight: $regular;
    text-transform: uppercase;
    margin: 0;
  }

  &__authorWrapper {
    @include clearfix;
  }

    &__exhibition {
      font-size: 16px;
      margin: 30px 0 0 0;
      font-weight: $ultra;
      text-align: right;
      float: right;
      text-transform: uppercase;

      a {
        color: inherit;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__author {
      font-size: 22px;
      font-weight: $semibold;
      margin: 30px 0 0 0;
      float: left;
    }

  &__technique {
    margin: 0;
    font-size: 18px;
    font-weight: $regular;
    text-transform: none;
  }
    
  &__name { 
    font-weight: $regular; 
    font-size: 22px; 
    clear: both; 
    margin: 0; 

    small { 
      display: inline; 
      font-size: 18px; 
    }
  }

  &__image {
    margin: 50px 0;
    img {
      max-width: none;
      width: 100%; 
      height: auto;
    }

    &.is-smaller {
      margin: 50px 20px;
    }
  }
    
    &__largeImage {
        margin: 50px 0;
        cursor: pointer;
    }

  &__description {
    margin: 0 20px;
      
    p {
      float: left;
      width: 70%;
      font-size: 20px;
      font-weight:$regular;
    }

    @include media($tablet) {
      p {
        width: auto;
      }
    }
  }

  &__descriptionJury {
    display: inline-block;
    float: right;
    height: 50px;
    width: 160px;
    background-color: black;
    color: white;
    line-height: 50px;
    font-size: 16px;
    font-weight: $regular;
    text-align: center;

    @include media($tablet) {
      display: block;
      float: none;
      width: auto;
    }
  }

  &__back {
    margin: 0 20px;

    a {
      display: inline-block;
      font-size: 18px;
      font-weight: $regular; 
      color: black;
      text-transform: uppercase;
    }
  }
}
